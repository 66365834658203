import React, {useState} from "react";
import './VideoSection.css';
import {Parallax} from "react-scroll-parallax";
import feathers from "../../i/feathers-1.png"
import appleMusic from '../../i/apple-music.png';
import spotify from '../../i/spotify.png'
import Logo from "../../components/header/logo/Logo";
import {useTranslation} from "react-i18next";

const VideoSection = () => {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className='videoSectionContainer' id='project'>
            <h2 className='videoSectionHeading'>{t('videoSection.videoSectionTitle')}</h2>
            <p className='videoSectionParagraph'>
                <span className='videoParagraphFirst'>
                   {t('videoSection.firstParagraph')}
                </span>
            </p>
            <div className='videoLinksSection mb-4'>
                <div>
                    <p> {t('videoSection.secondParagraph')}</p>
                </div>
                <div className='d-flex justify-content-center  mt-4'>
                    <Logo imgSource={appleMusic}
                          link={'https://music.apple.com/us/album/phoenix-feat-billy-sheehan-single/1603104319'}
                    />
                    <Logo imgSource={spotify}
                          link={'https://open.spotify.com/track/70Zg9dv14sZVBkwNNG1Lge?si=bcc4f434cf5a4aa4'}/>
                </div>

            </div>
            <div className='accordionSection'>

                <p >
                    {t('videoSection.videoSectionAccordion.accordionP1')}
                    <br/>
                    <br/>
                    {t('videoSection.videoSectionAccordion.accordionP2')}
                    <br/>
                    <br/>
                    {t('videoSection.videoSectionAccordion.accordionP3')}
                </p>
            </div>
            <div className="videoContainer">
                <iframe className='videoMain' src="https://www.youtube.com/embed/8Wud-9MIdZ8"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>

                </iframe>
            </div>
            <div className="featherSectionContainer">
                <Parallax speed={-10}>
                    <div className='featherSectionParallaxLeft'>
                        <img src={feathers} alt=""/>
                    </div>
                </Parallax>
                <Parallax speed={-10}>
                    <div className='featherSectionParallaxRight'>
                        <img src={feathers} alt=""/>
                    </div>
                </Parallax>
            </div>
        </div>
    );
}

export default VideoSection;
