import React from "react";
import "./Faq.css"
import Faqs from "../../components/Faqs/Faqs";
import {useTranslation} from "react-i18next";



const Faq = () => {
    const {t} = useTranslation();
    const answer4 = [t('faqs.answers.answer4.1'), t('faqs.answers.answer4.2'), t('faqs.answers.answer4.3'), t('faqs.answers.answer4.4'), t('faqs.answers.answer4.5'), t('faqs.answers.answer4.6'), t('faqs.answers.answer4.7'), t('faqs.answers.answer4.8'),t('faqs.answers.answer4.9'),t('faqs.answers.answer4.10')]
    const answer5 = [t('faqs.answers.answer5.1'),t('faqs.answers.answer5.2'),t('faqs.answers.answer5.3'),t('faqs.answers.answer5.4'),t('faqs.answers.answer5.5'),t('faqs.answers.answer5.6'),]
    return (
        <div className="faqSectionContainer" id='faq'>
            <h2 className='faqTitle mb-4'>{t('faqs.title')}</h2>
            <Faqs question={t('faqs.questions.question1')}
                  answer={[t('faqs.answers.answer1')]}/>
            <Faqs question={t('faqs.questions.question2')}
                  answer={[t('faqs.answers.answer2')]}/>
            <Faqs question={t('faqs.questions.question3')}
                  answer={[t('faqs.answers.answer3')]}/>
            <Faqs question={t('faqs.questions.question4')}
                  answer={answer4}/>
            <Faqs question={t('faqs.questions.question5')}
                  answer={answer5}/>
            <Faqs question={t('faqs.questions.question6')}
                  answer={[t('faqs.answers.answer6')]}/>
            <Faqs question={t('faqs.questions.question7')}
                  answer={[t('faqs.answers.answer7')]}/>
            <Faqs question={t('faqs.questions.question8')}
                  answer={[t('faqs.answers.answer8')]}/>
        </div>
    );
}
export default Faq;
