import React from "react";
import './Community.css';


const Community = ({communityImg, communityDescription1, communityDescription2}) => {
    return (
        <div className='communityContainer'>
            <img src={communityImg} alt=""/>
            <div className="communityText1">
                <p>{communityDescription1}</p>
            </div>
            <div className="communityText2">
                <p>{communityDescription2}</p>
            </div>
        </div>
    );
}
export default Community;
