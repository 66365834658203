import React, {useState} from "react";
import './Roadmap.css';
import Roadmapitem from "../../components/roadmapItem/Roadmapitem";
import {useTranslation} from "react-i18next";
import bg from '../../innerAssets/Scene (25).jpeg';

const Roadmap = () => {
    const {t} = useTranslation();
    const roadmapArray = [
        {
            key: 'q1',
            headerHeading: t('phases.phase1Contents.title'),
            headerBottomLine:t('phases.phase1Contents.titleBottomLine'),
            headerText: t('phases.phase1Contents.description'),
            giveaways: [
                {
                    percent: t('phases.phase1Contents.giveaways.giveaway0.percentage'),
                    description: t('phases.phase1Contents.giveaways.giveaway0.description')
                },
                {
                    percent: t('phases.phase1Contents.giveaways.giveaway01.percentage'),
                    description: t('phases.phase1Contents.giveaways.giveaway01.description')
                },
                {
                    percent: t('phases.phase1Contents.giveaways.giveaway011.percentage'),
                    description: t('phases.phase1Contents.giveaways.giveaway011.description')
                },
                {
                    percent: t('phases.phase1Contents.giveaways.giveaway02.percentage'),
                    description: t('phases.phase1Contents.giveaways.giveaway02.description')
                },
                {
                    percent: t('phases.phase1Contents.giveaways.giveaway03.percentage'),
                    description: t('phases.phase1Contents.giveaways.giveaway03.description')
                },
                {
                    percent: t('phases.phase1Contents.giveaways.giveaway04.percentage'),
                    description: t('phases.phase1Contents.giveaways.giveaway04.description')
                },
                {
                    percent: t('phases.phase1Contents.giveaways.giveaway05.percentage'),
                    description: t('phases.phase1Contents.giveaways.giveaway05.description')
                },
                {
                    percent: t('phases.phase1Contents.giveaways.giveaway1.percentage'),
                    description: t('phases.phase1Contents.giveaways.giveaway1.description')
                },
                {
                    percent: t('phases.phase1Contents.giveaways.giveaway2.percentage'),
                    description: t('phases.phase1Contents.giveaways.giveaway2.description')
                },
                {
                    percent: t('phases.phase1Contents.giveaways.giveaway3.percentage'),
                    description: t('phases.phase1Contents.giveaways.giveaway3.description')
                },
                {
                    percent: t('phases.phase1Contents.giveaways.giveaway4.percentage'),
                    description: t('phases.phase1Contents.giveaways.giveaway4.description')
                },

            ]
        },
        {
            key: 'q2',
            headerText: t('phases.phase2Contents.title'),
            headerHeading: t('phases.phase2Contents.description'),
            giveaways: [{description: t('phases.phase2Contents.giveaways.giveaway1')}]
        },
        {
            key: 'q3',
            headerText: t('phases.phase3Contents.title'),
            headerHeading: t('phases.phase3Contents.description'),
            giveaways: [{description: t('phases.phase3Contents.giveaways.giveaway1')}]
        },
        {
            key: 'q4',
            headerText: t('phases.phase4Contents.title'),
            headerHeading: t('phases.phase4Contents.description'),
            giveaways: []
        }

    ]

    const [quarter, setQuarter] = useState('q1');
    const handleChangeQuarter = (quarter) => {
        setQuarter(quarter);
    };


    return (
        <div className='roadmapContainer' id='roadmap'>

            <div className="roadmapTextContainer">
                <h2 className='roadmapTextHeading mb-4'>{t('roadmap.title')} </h2>
                <p className=' roadmapTextParagraph mb-4'>{t('roadmap.description')}</p>
                <div className="roadmapButtonsContainer">
                    <div className="row">
                        <div className="col-6 col-md-3">
                            <button onClick={() => setQuarter('q1')}>
                                <p>{t('roadmap.phaseTitles.title1')}</p>
                                <p>{t('roadmap.phaseTitles.title1description')}</p>
                            </button>
                        </div>
                        <div className="col-6 col-md-3">
                            <button onClick={() => setQuarter('q2')}>
                                <p>{t('roadmap.phaseTitles.title2')}</p>
                                <p>{t('roadmap.phaseTitles.title2description')}</p>
                            </button>
                        </div>
                        <div className="col-6 col-md-3">
                            <button onClick={() => setQuarter('q3')}>
                                <p>{t('roadmap.phaseTitles.title3')}</p>
                                <p>{t('roadmap.phaseTitles.title3description')}</p>
                            </button>
                        </div>
                        <div className="col-6 col-md-3">
                            <button onClick={() => setQuarter('q4')}>
                                <p>{t('roadmap.phaseTitles.title4')}</p>
                                <p>{t('roadmap.phaseTitles.title4description')}</p>
                            </button>
                        </div>
                    </div>
                </div>
                {roadmapArray.filter((curr) => {
                    return curr.key === quarter
                }).map((curr) => {
                    console.log(curr);
                    return (
                        <Roadmapitem giveaways={curr.giveaways} headerText={curr.headerText}
                                     headerHeading={curr.headerHeading}
                                     headerHeadingBottomLine={curr.headerBottomLine}
                                     giveawayText={t('phases.phase1Contents.giveaways.giveaway')}/>
                    );
                })}

            </div>

        </div>
    );
}

export default Roadmap;
