import React, {useState} from "react";
import './Faqs.css';

const Faqs = ({question, answer}) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className='faqsContainer'>
            <button onClick={() => setIsOpen(!isOpen)}>{question} {isOpen ? '-' : '+'}</button>
            <div className="faqsAnswer">
                <ul style={{display: isOpen ? 'block' : 'none'}}>
                    {answer.map((curr) => {
                        return <li className='mb-2'>{curr}</li>
                    })}
                </ul>
            </div>
        </div>
    );
}

export default Faqs;
