import common from './common.json'


const en = {
    en: {
        common
    },
}

export default en
