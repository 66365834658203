import './App.css';
import {Component} from "react";
import Navbar from "./containers/navbar/Navbar";
import UpcomingSection from "./containers/upcomingSection/UpcomingSection";
import CommunitySection from "./containers/communitySection/CommunitySection";
import VideoSection from "./containers/videoSection/VideoSection";
import BandMembers from "./containers/bandMembers/BandMembers";
import {ParallaxProvider} from "react-scroll-parallax";
import ParallaxSection from "./containers/parallaxSection/ParallaxSection";
import Faq from "./containers/Faq/Faq";
import Footer from "./containers/footer/Footer";
import TreasureHunt from "./containers/treasureHuntSection/TreasureHunt";
import SneakPeek from "./containers/sneakPeek/SneakPeek";
import Roadmap from "./containers/roadmap/Roadmap";
import './i18n';
import MintInfo from "./containers/mintInfo/MintInfo";
import MusicSection from "./containers/musicSection/MusicSection";

class App extends Component {
    constructor(props) {

        super(props);
        this.state = {
            web3Provider: '',
        }
    }

    render() {
        return (
            <div className='mainContainer'>
                <Navbar/>
                <ParallaxProvider>
                    <MintInfo/>
                </ParallaxProvider>
                <ParallaxProvider>
                    <UpcomingSection/>
                </ParallaxProvider>

                <ParallaxProvider>
                    <ParallaxSection/>
                </ParallaxProvider>
                <SneakPeek/>
                <ParallaxProvider>
                    <VideoSection/>
                </ParallaxProvider>
                <CommunitySection/>
                <ParallaxProvider>
                    <TreasureHunt/>
                </ParallaxProvider>
                <Roadmap/>
                <ParallaxProvider>
                    <Faq/>
                </ParallaxProvider>
                <ParallaxProvider>
                    <BandMembers/>
                </ParallaxProvider>
                <Footer/>
            </div>
        );
    }
}

export default App;
