import React from "react";
import './Footer.css';
import poweredBy from "../../innerAssets/powered by rocknverse logo medium.png";
import Logo from "../../components/header/logo/Logo";
import discord from "../../innerAssets/discord.png";
import instagram from "../../innerAssets/instagram.png";
import twitter from "../../innerAssets/twitter.png";
import medium from "../../innerAssets/medium.png";
import {useTranslation} from "react-i18next";


const Footer = () => {
    const {t} = useTranslation();

    return (
        <div className='footerContainer'>
            <div className="footerContactUs">
                <div className="contactUsHeader">
                    <h2>{t('footer.title')} </h2>
                </div>
                <div className="contactUsLogo">

                    <Logo imgSource={discord} link='https://discord.com/invite/3bEsSC45uG'/>
                    <Logo imgSource={instagram} link='https://www.instagram.com/rocknverse/?utm_medium=copy_link'/>
                    <Logo imgSource={twitter} link='https://twitter.com/rocknverse'/>
                    <Logo imgSource={medium} link='https://medium.com/@rocknverse'/>
                </div>

            </div>
            <div className="footerLogo">
                <img src={poweredBy} alt=""/>
            </div>
        </div>
    );
}

export default Footer;
