import React from "react";
import './CommunitySection.css';
import communityImg1 from '../../i/project-guitar-blue.png'
import communityImg2 from '../../i/project-guitar.png'
import communityImg3 from '../../i/project-avatar.png'
import communityImg4 from '../../i/refundImg1.png'
import bsheean from '../../i/billy.png';
import bartuvepembe from '../../i/bartuvepembe.png';
import kertenkele from '../../i/kertenkele.png';
import selbondvekus from '../../i/selbondvekus.png';
import jonsnowabenzeyen from '../../i/jonsnowabenzeyen.png';
import Community from "../../components/community/Community";
import hint from '../../i/ipucu görseli.png';

import {useTranslation} from "react-i18next";
import ReactAudioPlayer from 'react-audio-player';

const CommunitySection = () => {


    const {t} = useTranslation();


    return (
        <div className='CommunityContainer' id='giveaways'>
            <div className='CommunityCard'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="communityTitle">
                            <p>{t('communitySection.communitySectionTitle')}</p>
                            <a>{t('communitySection.communitySectionDescription')}</a>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <Community communityImg={communityImg1}
                                       communityDescription1={t('communitySection.communitySectionCards.card1.cardDescription1')}
                                       communityDescription2={t('communitySection.communitySectionCards.card1.cardDescription2')}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <Community communityImg={communityImg2}
                                       communityDescription1={t('communitySection.communitySectionCards.card2.cardDescription1')}
                                       communityDescription2={t('communitySection.communitySectionCards.card2.cardDescription2')}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <Community communityImg={communityImg3}
                                       communityDescription1={t('communitySection.communitySectionCards.card3.cardDescription1')}
                                       communityDescription2={t('communitySection.communitySectionCards.card3.cardDescription2')}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <Community communityImg={communityImg4}
                                       communityDescription1={t('communitySection.communitySectionCards.card4.cardDescription1')}
                                       communityDescription2={t('communitySection.communitySectionCards.card4.cardDescription2')}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <Community communityImg={bsheean}
                                       communityDescription1={t('communitySection.communitySectionCards.card5.cardDescription1')}
                                       communityDescription2={t('communitySection.communitySectionCards.card5.cardDescription2')}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <Community communityImg={bartuvepembe}
                                       communityDescription1={t('communitySection.communitySectionCards.card6.cardDescription1')}
                                       communityDescription2={t('communitySection.communitySectionCards.card6.cardDescription2')}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <Community communityImg={kertenkele}
                                       communityDescription1={t('communitySection.communitySectionCards.card7.cardDescription1')}
                                       communityDescription2={t('communitySection.communitySectionCards.card7.cardDescription2')}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <Community communityImg={selbondvekus}
                                       communityDescription1={t('communitySection.communitySectionCards.card8.cardDescription1')}
                                       communityDescription2={t('communitySection.communitySectionCards.card8.cardDescription2')}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <Community communityImg={jonsnowabenzeyen}
                                       communityDescription1={t('communitySection.communitySectionCards.card9.cardDescription1')}
                                       communityDescription2={t('communitySection.communitySectionCards.card9.cardDescription2')}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-3">
                            <Community communityImg={hint}
                                       communityDescription1={t('communitySection.communitySectionCards.card10.cardDescription1')}
                                       communityDescription2={t('communitySection.communitySectionCards.card10.cardDescription2')}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CommunitySection;
