import React from "react";
import './UpcomingSection.css';
import feathers from '../../innerAssets/Scene_5.png';
import {Parallax} from "react-scroll-parallax";
import {useTranslation} from "react-i18next";

const UpcomingSection = () => {
    const { t } = useTranslation();
    return (
        <div className='upcomingSectionContainer container'>


            <div className='upcomingSectionText'>
                <h2 className='upcomingSectionHeading'>{t('upComingSection.upComingTitle')}</h2>
                <ul className='upcomingSectionList'>
                    <li>{t('upComingSection.upComingP1')}</li>
                    <li>{t('upComingSection.upComingP2')}</li>
                    <li>{t('upComingSection.upComingP3')}</li>
                </ul>
            </div>

            <Parallax speed={-15}>
                <div className='upcomingSectionParallaxRight'>
                    <img src={feathers} alt=""/>
                </div>
            </Parallax>
            <Parallax speed={-15}>
                <div className='upcomingSectionParallaxLeft'>
                    <img src={feathers} alt=""/>
                </div>
            </Parallax>
        </div>
    );
}

export default UpcomingSection;
