import React from "react";
import './Logo.css';


const Logo = ({imgSource, link}) => {
    return (
        <a href={link} target='_blank'>
            <div className='logoContainer'>

                <img src={imgSource} alt="logo"/>

            </div>
        </a>
    );
}

export default Logo;
