import React, {Component} from "react";
import './BandMembers.css';
import BandMember from "../../components/bandMember/BandMember";
import bandMember1 from '../../i/skinrunners-1.png'
import bandMember2 from '../../i/skinrunners-2.png'
import bandMember3 from '../../i/member-selcuk-eser.png'
import bandMember4 from '../../i/skinrunners-4.png'
import bandMember5 from '../../i/skinrunners-5.png'
import bandMember6 from '../../i/skinrunners-6.png'
import {Parallax} from "react-scroll-parallax";
import {useTranslation} from "react-i18next";

const BandMembers = () => {
    const {t} = useTranslation();
    return (
        <div className='bandMembersContainer' id='bandmembers'>
            <div className="bandMembersHeading">
                {/*<Parallax translateX={['250px', '-150px']}>*/}
                {/*    <img src={skinrunnersLogo} alt=""/>*/}
                {/*</Parallax>*/}

                <h2>{t('BandMembers.title')}</h2>
            </div>
            <div className='bandMembersCards'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                            <BandMember bandMemberImg={bandMember1}
                                        bandMemberDescription={t('BandMembers.members.member1')}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                            <BandMember bandMemberImg={bandMember2}
                                        bandMemberDescription={t('BandMembers.members.member2')}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                            <BandMember bandMemberImg={bandMember3}
                                        bandMemberDescription={t('BandMembers.members.member3')}/>
                        </div>
                        <div className="col-12 col-md-6  col-lg-4 col-xl-2">
                            <BandMember bandMemberImg={bandMember4}
                                        bandMemberDescription={t('BandMembers.members.member4')}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                            <BandMember bandMemberImg={bandMember5}
                                        bandMemberDescription={t('BandMembers.members.member5')}/>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-xl-2">
                            <BandMember bandMemberImg={bandMember6}
                                        bandMemberDescription={t('BandMembers.members.member6')}/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BandMembers;
