import React from "react";
import './mintInfo.css';
import pence from '../../i/pençe.png';
import anaMetin from '../../i/ana metin.png';
import subText from '../../i/yan metin.png';
import phoenix1 from '../../i/anka 1.png';
import phoenix2 from '../../i/anka 2.png';
import phoenix4 from '../../i/anka 4.png';
import phoenix5 from '../../i/anka 5.png';
import phoenix6 from '../../i/anka 6.png';
import feathers1 from '../../i/tüy 1.png';
import feathers2 from '../../i/tüy 2.png';
import feathers3 from '../../i/tüy 3.png';
import mountain from '../../i/dağ.png';
import {Parallax} from "react-scroll-parallax";
import clouds from '../../i/pammk_4.png';
import skinrunnersLogo from "../../i/skinrunnersblack.png";
import {useTranslation} from "react-i18next";

const MintInfo = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="img2">
                <Parallax translateX={['0px', '0px']}>
                    <img className='parallaxSkinrunnersMainLogo' src={skinrunnersLogo} alt="" loading='lazy'/>
                </Parallax>
            </div>
            <div className='mintInfoContainer'>
                <img src={clouds} alt='' className='mountain' loading='lazy'/>
                <Parallax>
                    <img src={phoenix1} alt='' className='phoenix1' loading='lazy'/>
                </Parallax>
                <img src={phoenix2} alt='' className='phoenix2' loading='lazy'/>
                <img src={phoenix4} alt='' className='phoenix4' loading='lazy'/>
                <img src={phoenix5} alt='' className='phoenix5' loading='lazy'/>
                <img src={phoenix6} alt='' className='phoenix6' loading='lazy'/>
                <img src={feathers1} alt='' className='feathers1' loading='lazy'/>
                <img src={feathers2} alt='' className='feathers2' loading='lazy'/>



                <img className='mintInfoClaw' src={pence} alt='' loading='lazy'/>
                <div className='mintContainerSubText'>
                    <img src={subText} alt='' loading='lazy'/>
                    <p className='mintContainerSubTextFirstParagraph'>{t('mintInfo.mintDate')}</p>
                    <p className='mintContainerSubTextSecondParagraph'>{t('mintInfo.mintPrice')}</p>
                </div>
                <div className='mintContainerMainText'>
                    <img src={anaMetin} alt='' loading='lazy'/>
                    <p className='mintContainerMainTextFirstParagraph'>{t('mintInfo.supply')}</p>
                    <p className='mintContainerMainTextSecondParagraph'>{t('mintInfo.blockchain')}</p>
                </div>

            </div>
        </>
    );
}

export default MintInfo;
