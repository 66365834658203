import common from './common.json'


const tr = {
    tr: {
        common,
    },
}

export default tr
