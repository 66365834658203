import React from "react";
import './TreasureHunt.css';
import Community from "../../components/community/Community";
import chopper from '../../i/project-chopper.png'
import {Parallax} from "react-scroll-parallax";
import crow from "../../i/birds-1.png";
import {useTranslation} from "react-i18next";

const TreasureHunt = () => {
    const {t} = useTranslation();
    return (
        <div className='container-fluid'>

            <div className="treasureHuntContainer">

                <div className="treasureHuntText">
                    <h2>{t('treasureSection.title')}</h2>
                    <p>{t('treasureSection.description')}</p>
                </div>

                <div className="treasureHuntCard">
                    <div className="crowSectionParallaxContainer">
                        <Parallax translateX={['0px', '1600px']} speed={0}>
                            <div className='crowSectionParallax'>
                                <img src={crow} alt=""/>
                            </div>
                        </Parallax>
                    </div>
                    <Community communityImg={chopper}
                               communityDescription1={t('treasureSection.treasureSectionCard.cardText1')}
                               communityDescription2={t('treasureSection.treasureSectionCard.cardText2')}
                    />
                </div>

            </div>

        </div>
    );
}

export default TreasureHunt;
