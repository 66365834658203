import Logo from "../../components/header/logo/Logo";
import discord from '../../innerAssets/discord.png';
import instagram from '../../innerAssets/instagram.png';
import twitter from '../../innerAssets/twitter.png';
import medium from '../../innerAssets/medium.png';
import './Navbar.css';
import React, {useState} from "react";
import poweredBy from "../../innerAssets/powered by rocknverse logo medium.png";
import {useTranslation} from "react-i18next";
import classNames from 'classnames'
import i18next from "i18next";
import cookies from 'js-cookie'

const Navbar = () => {
    const languagesRV = [{
        code: "en",
        name: 'English',
        country_code: 'gb'
    },
        {
            code: 'tr',
            name: 'Türkçe',
            country_code: 'tr'
        }]
    const GlobeIcon = ({width = 24, height = 24}) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            fill="currentColor"
            className="bi bi-globe globe"
            viewBox="0 0 16 16"
        >
            <path
                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
        </svg>
    )
    const {t} = useTranslation()
    const currentLanguageCode = cookies.get('i18next') || 'en'
    const currentLanguage = languagesRV.find((l) => l.code === currentLanguageCode)
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className='navbarContainer'>
            <div className="dropdown dropdownMain">
                <button
                    className="btn btn-link dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                    <GlobeIcon/>
                </button>
                <ul className="dropdown-menu shadow-none" aria-labelledby="dropdownMenuButton1">
                    <li className="shadow-none">
                        <span className="dropdown-item-text"/>
                    </li>
                    {languagesRV.map(({code, name, country_code}) => (
                        <li key={country_code}>
                            <a
                                href="#"
                                className={classNames('dropdown-item', {
                                    disabled: currentLanguageCode === code,
                                })}
                                onClick={() => {
                                    i18next.changeLanguage(code)
                                }}
                            >
                    <span
                        style={{
                            opacity: currentLanguageCode === code ? 0.5 : 1,
                        }}
                    />
                                {name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="navbarLogo">
                <a href='https://rocknverse.com/' target='_blank'>
                    <img src={poweredBy} alt=""/>
                </a>
            </div>
            {/*MOBILE MENU START*/}
            <div className="hamburger-menu">
                <input id="menu__toggle" type="checkbox" checked={isOpen} onClick={() => setIsOpen(!isOpen)}/>
                <label className="menu__btn" htmlFor="menu__toggle">
                    <span/>
                </label>

                <ul className="menu__box" style={{right: isOpen ? '0%' : '-110%'}}>
                    <li><a onClick={() => setIsOpen(false)} className="menu__item"
                           href="#project">{t('navBar.project')}</a></li>
                    <li><a onClick={() => setIsOpen(false)} className="menu__item"
                           href="#giveaways">{t('navBar.giveaways')}</a></li>
                    <li><a onClick={() => setIsOpen(false)} className="menu__item"
                           href="#bandmembers">{t('navBar.bandMembers')}</a></li>
                    <li><a onClick={() => setIsOpen(false)} className="menu__item"
                           href="#roadmap">{t('navBar.roadMap')}</a></li>
                    <li><a onClick={() => setIsOpen(false)} className="menu__item" href="#faq">{t('navBar.faq')}</a>
                    </li>
                </ul>
            </div>
            {/*MOBILE MENU FINISH*/}
            <div className="navbarCenter">
                <a href="#project">
                    <p className='navbarLink'>{t('navBar.project')}</p>
                </a>
                <a href="#giveaways">
                    <p className='navbarLink'>{t('navBar.giveaways')}</p>
                </a>
                <a href="#bandmembers">
                    <p className='navbarLink'>{t('navBar.bandMembers')}</p>
                </a>
                <a href="#roadmap">
                    <p className='navbarLink'>{t('navBar.roadMap')}</p>
                </a>
                <a href="#faq">
                    <p className='navbarLink'>{t('navBar.faq')}</p>
                </a>
                <div className="dropdown dropdownDesktop">
                    <button
                        className="btn btn-link dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <GlobeIcon/>
                    </button>
                    <ul className="dropdown-menu shadow-none" aria-labelledby="dropdownMenuButton1">
                        <li className="shadow-none">
                            <span className="dropdown-item-text"/>
                        </li>
                        {languagesRV.map(({code, name, country_code}) => (
                            <li key={country_code}>
                                <a
                                    href="#"
                                    className={classNames('dropdown-item', {
                                        disabled: currentLanguageCode === code,
                                    })}
                                    onClick={() => {
                                        i18next.changeLanguage(code)
                                    }}
                                >
                    <span className="disabledLang"
                          style={{
                              opacity: currentLanguageCode === code ? 0.5 : 1,
                          }}
                    />
                                    {name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="navbarLeft">
                <Logo imgSource={discord} link='https://discord.com/invite/3bEsSC45uG'/>
                <Logo imgSource={instagram} link='https://www.instagram.com/rocknverse/?utm_medium=copy_link'/>
                <Logo imgSource={twitter} link='https://twitter.com/rocknverse'/>
                <Logo imgSource={medium} link='https://medium.com/@rocknverse'/>
            </div>
        </div>
    );
}

export default Navbar;
