import React from "react";
import './Roadmapitem.css';

const Roadmapitem = ({headerHeading, headerText, headerHeadingBottomLine = '', giveaways, giveawayText}) => {
    return (
        <div className='perspectiveContainer'>
            <div className='roadmapQuarterContainer'>
                <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
                    <h3 className=' roadmapTextSmallHeading-0'>{headerHeading}</h3>
                    <h3 className='roadmapTextSmallHeading-0'>{headerHeadingBottomLine}</h3>
                    <p className='mt-2 roadmapTextParagraph'>{headerText}</p>
                </div>
                <div className='w-100 d-flex flex-column justify-content-center align-items-center'>
                    <h3 className='roadmapTextSmallHeading-0'>{giveaways.length === 0 ? '' : giveawayText}</h3>
                </div>
                {
                    giveaways.map((curr) => {
                        console.log(curr);
                        return (
                            <div>
                                <h3 className=" roadmapTextSmallHeading-2">{curr.percent ? curr.percent : ''}</h3>
                                <p className='mt-2 roadmapTextParagraph'>{curr.description ? curr.description : ''}</p>
                            </div>
                        );
                    })}
            </div>
        </div>

    );
}

export default Roadmapitem;
