import React from "react";
import './BandMember.css';


const BandMember = ({bandMemberImg, bandMemberDescription}) => {
    return (
        <div className='bandMemberContainer'>
            <img src={bandMemberImg} alt=""/>
            <div className="bandMemberText">
                <p>{bandMemberDescription}</p>
            </div>
        </div>
    );
}
export default BandMember;
