import React from "react";
import './ParallaxSection.css';
import teams from "../../i/teams-1.png"
import {Parallax} from "react-scroll-parallax";
import skinrunnersLogo from "../../i/skinrunnersblack.png";

const ParallaxSection = () => {
    return (
        <div className='parallaxSectionContainer'>

            <div className="img1">
                <Parallax translateX={['-250px', '0px']}>
                    <img src={teams} alt="teams"/>
                </Parallax>
            </div>

        </div>
    );
}

export default ParallaxSection;
