import React, {useEffect, useState} from "react";
import './sneakPeek.css';
import sp1 from '../../i/ss7.png';
import sp2 from '../../i/ss6.png';
import sp3 from '../../i/ss5.png';
import sp4 from '../../i/ss4.png';
import sp5 from '../../i/ss3.png';
import sp6 from '../../i/ss2.png';
import sp7 from '../../i/ss1.png';


const SneakPeek = () => {
    const itemArray = [sp1, sp2, sp3, sp4, sp5, sp6, sp7]
    const [currentItem, setCurrentItem] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentItem((currentItem) => {
                if(currentItem === 6){
                    return 0;
                }else{
                    return currentItem + 1;
                }
            });

        }, 1000);
        return () => clearInterval(interval);
    }, []);
    return (
        <>
            <div className='sneakPeekContainer container'>

                <div className='sneakPeekItemContainer item1'>
                    <img src={sp1} alt='' className='sneakPeekItem'/>
                </div>
                <div className='sneakPeekItemContainer item2'>
                    <img src={sp2} alt='' className='sneakPeekItem'/>
                </div>
                <div className='sneakPeekItemContainer item3'>
                    <img src={sp3} alt='' className='sneakPeekItem'/>
                </div>
                <div className='sneakPeekItemContainer item4'>
                    <img src={sp4} alt='' className='sneakPeekItem'/>
                </div>
                <div className='sneakPeekItemContainer item5'>
                    <img src={sp5} alt='' className='sneakPeekItem'/>
                </div>
                <div className='sneakPeekItemContainer item6'>
                    <img src={sp6} alt='' className='sneakPeekItem'/>
                </div>
                <div className='sneakPeekItemContainer item7'>
                    <img src={sp7} alt='' className='sneakPeekItem'/>
                </div>

            </div>
            <div className='sneakPeekContainerMobile'>
                <img src={itemArray[currentItem]} alt=''/>
            </div>
        </>
    );
}

export default SneakPeek;
