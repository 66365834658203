import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import translationEN from './locales/en/index'
import translationTR from './locales/tr/index'
import HttpApi from "i18next-http-backend"
import Cookies from "universal-cookie"
import LanguageDetector from "i18next-browser-languagedetector";

const cookies = new Cookies()

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        resources: {
            ...translationEN,
            ...translationTR,
        },
        ns: ['common'],
        defaultNS: 'common',
        //lng: cookies.get('lang'),
        supportedLngs: ['en', 'tr'],
        fallbackLng: 'en',
        detection: {
            order: ['cookie','htmlTag', 'localStorage', 'path', 'subdomain'],
            caches: ['cookie'],
        },
        backend: {
            loadPath: '/locales/{{lng}}/common.json',
        },

        debug: false,
        cleanCode: true,
        interpolation: {
            escapeValue: false,
        },
        load: 'languageOnly',
        react: {
            useSuspense: false,
        },
    })

    .then(r => console.log(r));

export default i18n;
